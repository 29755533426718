<template>
  <!-- 轮播图 -->
    <Banner :imgArr="state.bannerArr" v-if="state.bannerArr!=''" />
    <div v-if="state.isKong" class="kong">
      <img src="@/assets/images/kong.png" />
      暂无内容
    </div>
    <div v-else>
      <div class="section">
        <Tit :title = "state.title1" />
        <Card1 :tdList="state.tdList" />
      </div>
      <div class="container">
        <div class="section">
          <Tit :title = "state.title2" />
          <p class="intro1 animate__animated animate__bounceInUp wow">{{state.intro.describe}}</p>
          <smallimg :imgArr="state.smallImg"  v-if="state.smallImg!=''" />
        </div>
      </div>
      <div class="section">
        <Tit :title = "state.title3" />
        <!-- <Section3 :section3List="section3List" /> -->
        <subCard :tdList="state.gnList" v-if="state.gnList" /> 
      </div>
      <div class="container">
        <div class="section" v-if="state.chImg!='' ">
          <Tit :title = "state.title" />
          <img :src="state.chImg" style="margin:0 auto"  class="intro1 animate__animated animate__bounceInUp wow"/>
          <!-- <Fangan :faList="faList" /> -->
        </div>
      </div>
  </div>
</template>

<script>
import {onMounted, reactive,watchEffect,nextTick} from "vue";
import { useRoute } from 'vue-router'
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
// import Section3 from "@/components/liangdian.vue";
import Tit from './components/titCard.vue'
import subCard from'./components/subCard.vue'
// import Fangan from './components/fangan.vue'
import Card1 from './components/card1.vue'
import smallimg from './components/banner.vue'
import { getbannerData,getSoftware} from "../../api/request"
export default {
  name: "Product",
  components:{
    Banner,
    // Section3,
    Tit,
    subCard,
    // Fangan,
    Card1,
    smallimg
  },
  setup() {
    const route = useRoute()
    onMounted(()=>{
            // var wow = new WOW({
            // boxClass: 'wow',    //需要执行动画元素的Class
            // animateClass: 'animated',    //animation.css动画的Class
            // offset: 100,    //距离可视区域多少开始执行动画
            // mobile: true,    //是否在移动设备执行动画
            // live: true    //异步加载的内容是否有效
            // })
            // wow.init()
            // getData()
    })
    let state = reactive({
      bannerArr:[],
      isKong:false,
      curId:null,
      tdList:[],
      intro:"",
      gnList:[],
      smallImg:[],
      title1:"",
      title2:"",
      title3:""
    })

    const tdList=[
          {id:1,title:"生长过程缺乏管理",info:"农产品生长环境无监控，靠天吃饭，各项参数了解不足，无法提前预警问题，易导致农产品的产量质量出现问题",img1:require('@/assets/images/yingjian/01.png'),img2:require('@/assets/images/yingjian/icon01.png')},
          {id:2,title:"缺乏专业种植技术指导",info:"仅靠以往种植经验积累，未能充分借鉴权威科学种植。种植过程中出现意外时，缺乏问题处理咨询渠道",img1:require('@/assets/images/yingjian/02.png'),img2:require('@/assets/images/yingjian/icon02.png')},
          {id:3,title:"缺少先进技术支撑",info:"传统灌溉、施肥工作费时费力，信息化、机械化水平低下，忽视数据分析技术和现代化科技给农业所带来的效益提升",img1:require('@/assets/images/yingjian/03.png'),img2:require('@/assets/images/yingjian/icon03.png')},
          {id:4,title:"食品安全保障力度低",info:"缺少溯源管理，食品安全监管意识薄弱，出现问题无法快速定位原因。缺乏数据支撑，易导致农药喷洒过量",img1:require('@/assets/images/yingjian/04.png'),img2:require('@/assets/images/yingjian/icon04.png')}
        ]
    const section3List = [
      {id:1,title:"农业全流程管理",info:"对农产品从选种、种植、生产，到入库、销售、产品溯源，提供全流程优质的数字化管理，打造智慧农业产业园，形成农业产业园信息化一站式服务。",img:require('@/assets/images/section3/img1.png')},
      {id:2,title:"物联网远程监测",info:"利用物联网云平台、农业传感网等，建设生产管理中心。提供气象监测、土壤监测、虫情测报、水肥一体灌溉等无人值守解决方案，实现传统农业基地数字化、信息化、规范化、现代化。",img:require('@/assets/images/section3/img2.png')},
      {id:3,title:"大数据智能分析",info:"对农作物生产过程中育苗品种、地理环境、农药投量等数据进行记录、汇聚和关联分析，形成电子档案和趋势曲线。同时记录农产品销售数据，市场供应状况等进行分析，进行预期指导。。",img:require('@/assets/images/section3/img3.png')},
      {id:4,title:"农产品溯源管理",info:"建立农产品产销追溯体系，实行一物一码全流程追踪。消费者可通过扫描溯源码查看相应的产品生产地、生产场景、查询产品在生产过程中的环境检测数据。",img:require('@/assets/images/section3/img4.png')},
      {id:5,title:"农业专家指导",info:"农业专家系统支持农业专家入驻，农业人员可在平台互相沟通或咨询专家农业生产过程中遇到的问题，为农业人员提供丰富的信息查询及咨询平台。",img:require('@/assets/images/section3/img5.png')}
    ];
    const faList=[
      {id:1,icon:require('@/assets/images/yingjian/faicon01.png'),title:"无人化值守",info:"无需现场值守，自动化采集生长数据，远程控制农机设备"},
      {id:2,icon:require('@/assets/images/yingjian/faicon02.png'),title:"科学种植",info:"为种植过程提供专业种植知识，针对性解决疑难问题"},
      {id:3,icon:require('@/assets/images/yingjian/faicon03.png'),title:"智能分析",info:"实现数据资源的可视化和智能化，智能分析生长趋势"},
      {id:4,icon:require('@/assets/images/yingjian/faicon04.png'),title:"风险控制",info:"集成规范化生产溯源管理体系，迅速召回缺陷产品"}
    ]
    function getData(id){
      getbannerData({id:36}).then((res)=>{
        if(res.code == 200){
          state.bannerArr = res.data
        }
      })
      getSoftware({id}).then((res)=>{
        console.log(res)
        if(res.code == 200){
          if(res.data.title!=""){
            state.isKong = false
            state.tdList = res.data.title[0].list
            state.title1 = res.data.title[0].name
            state.intro = res.data.title[1].list[0]
            state.title2 = res.data.title[1].name
            state.title3 = res.data.title[2].name

            state.smallImg = res.data.title[1].list[0].imgs
            state.gnList = res.data.title[2].list
            if(res.data.title[3]){
                state.chImg = res.data.title[3].list[0].img
                state.title = res.data.title[3].list[0].name
            }else{
              state.title = ""
              state.chImg=""
            }
          }else{
            state.isKong = true
          }
          
        }
        
      })
    }
    watchEffect(()=>{
      if(!state.isKong){
        nextTick(()=>{
          var wow = new WOW({
              boxClass: 'wow',    //需要执行动画元素的Class
              animateClass: 'animated',    //animation.css动画的Class
              offset: 10,    //距离可视区域多少开始执行动画
              mobile: true,    //是否在移动设备执行动画
              live: true,    //异步加载的内容是否有效
              iteration:2
          })
          wow.init()
        })
      }
        state.curId = route.query.id
        let id = state.curId
        getData(id)
        window.scrollTo(0,0);
    })
    return {
      tdList,
      section3List,
      faList,
      state,
      getData
    };
  },
};
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  background: #f6f8fb;
  margin-top: .5rem;
  padding-bottom: 1rem;
}
  .section{
    width: 12rem;
    margin: 0 auto;
    
    .intro1{
      font-size: .14rem;
      color: #555555;
      font-size: .18rem;
      line-height: .36rem;
      padding-bottom: .5rem;
    }
    img{
      max-width:100%
    }
  }
  .kong{
    width: 12rem;
    height: 6rem;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: .2rem;
    color: #666;
    img{
      width: 4.21rem;
      margin: .5rem auto 1rem;
    }
  }
</style>