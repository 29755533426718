<template>
    <ul class="section" v-if="tdList">
        <li v-for="(item,index) in tdList" :key="index" class="animate__animated wow" :class="index%2 == 0 ? 'animate__bounceInRight' : 'animate__bounceInLeft' ">
            <div class="secBox">
                <div class="title"><span class="numIcon">{{'0'+(index+1)}}</span><span>{{item.name}}</span></div>
                <p>{{item.describe}}</p>
                <!-- <img :src="item.img" class="iconBg" /> -->
                <img src="@/assets/images/rj1.png" class="iconBg" v-if="index==0" />
                <img src="@/assets/images/rj2.png" class="iconBg" v-if="index==1" />
                <img src="@/assets/images/rj3.png" class="iconBg" v-if="index==2" />
                <img src="@/assets/images/rj4.png" class="iconBg" v-if="index==3" />
            </div>
        </li>
    </ul>
</template>
<style  lang="scss" scoped>
    .section{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
            font-size: .14rem;
            margin-bottom: .2rem;
            .secBox{
                height: 1.82rem;
                padding:.26rem .36rem;
                box-sizing: border-box;
                background: #F3F6F9;
                border-radius: .1rem;
                position: relative;
                .title{
                    display: flex;
                    font-size: .2rem;
                    margin-bottom: .16rem;
                    font-weight: bold;
                    .numIcon{
                        width: .26rem;
                        height: .26rem;
                        background: #EF6D1A;
                        border-radius: .03rem;
                        color: #fff;
                        line-height: .26rem;
                        text-align: center;
                        font-weight: bold;
                        font-size: .16rem;
                        margin-right: .2rem;
                    }
                }
                p{
                    font-size: .16rem;
                    color: #999999;
                }
                .iconBg{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1.92rem;
                    height: 1.82rem;
                }
            }
        }
        li:nth-child(1),li:nth-child(4){
            .secBox{
                width: 7rem;
                padding-right: 1.92rem;
                p{
                    line-height: .36rem;
                    height: .72rem;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2; 
                }
            }
                
        }
        li:nth-child(2),li:nth-child(3){
            .secBox{
                width: 4.7rem;
                padding-right: .92rem;
                p{
                    line-height: .3rem;
                    height: .9rem;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:3; 
                }
            }
        }
        li:nth-child(3),li:nth-child(4){
            animation-delay: .5s;
        }
    }
</style>
<script>
import {onMounted,watchEffect,nextTick} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
    props:['tdList'],
    setup(props){
        onMounted(()=>{
            // var wow = new WOW({
            // boxClass: 'wow',    //需要执行动画元素的Class
            // animateClass: 'animated',    //animation.css动画的Class
            // offset: 100,    //距离可视区域多少开始执行动画
            // mobile: true,    //是否在移动设备执行动画
            // live: true    //异步加载的内容是否有效
            // })
            // wow.init()
        })
        watchEffect(()=>{
            console.log(props.tdList)
            if(props.tdList!=""){
                nextTick(()=>{
                    var wow = new WOW({
                        boxClass: 'wow',    //需要执行动画元素的Class
                        animateClass: 'animated',    //animation.css动画的Class
                        offset: 10,    //距离可视区域多少开始执行动画
                        mobile: true,    //是否在移动设备执行动画
                        live: true,    //异步加载的内容是否有效
                        iteration:2
                    })
                    wow.init()
                })
            }
        })
    }
}
</script>