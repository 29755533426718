<template>
    <el-carousel :interval="4000" type="card" height="4.8rem" indicator-position="none" class="animate__animated animate__bounceInUp wow">
      <el-carousel-item v-for="(item, index) in imgArr"  :key="index"  :class="'index' + index">
            <img :src="item.images" />
      </el-carousel-item>
    </el-carousel>
</template>
<script>
export default {
    props:{
        imgArr:{
            type:Array
        }
    }
};
</script>
<style lang='scss' scoped>
.el-carousel__item {
  text-align: center;
  img{
    width: 2.36rem;
    height: 4.80rem;
  }
}
</style>