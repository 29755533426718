<template>
    <ul class="section">
        <li v-for="(item,index) in tdList" :key="index" class="animate__animated animate__bounceInUp wow">
            <div class="secBox">
                <h2>{{item.name}}</h2>
                <p>{{item.describe}}</p>
                <div v-if="index==0"><img src="@/assets/images/yingjian/01.png" class="img1" /><img src="@/assets/images/yingjian/icon01.png" class="img2" /></div>
                <div v-if="index==1"><img src="@/assets/images/yingjian/02.png" class="img1" /><img src="@/assets/images/yingjian/icon02.png" class="img2" /></div>
                <div v-if="index==2"><img src="@/assets/images/yingjian/03.png" class="img1" /><img src="@/assets/images/yingjian/icon03.png" class="img2" /></div>
                <div v-if="index==3"><img src="@/assets/images/yingjian/04.png" class="img1" /><img src="@/assets/images/yingjian/icon04.png" class="img2" /></div>
                <div v-if="index==4"><img src="@/assets/images/yingjian/05.png" class="img1" /><img src="@/assets/images/yingjian/icon05.png" class="img2" /></div>
            </div>
        </li>
    </ul>
</template>
<style  lang="scss" scoped>
    .section{
        display: flex;
        justify-content: space-between;
        li{
            font-size: .14rem;
            .secBox{
                width: 2.2rem;
                // height: 2.5rem;
                padding:.6rem .28rem ;
                box-sizing: border-box;
                background: #f6f8fb;
                border-radius: .1rem;
                position: relative;
                h2{
                    margin-bottom: .1rem;
                    font-size: .2rem;
                    color: #333;
                    font-weight: normal;
                }
                p{
                    color: #666;
                    font-size: .14rem;
                    line-height: .26rem;
                    padding-top: .1rem;
                }
                .img1{
                    position: absolute;
                    top: .4rem;
                    left: .28rem;
                    width: .48rem;
                    height: .5rem;
                }
                .img2{
                    position: absolute;
                    bottom: .05rem;
                    right: .1rem;
                    width: .8rem;
                    height: .8rem;
                }
            }

        }
        li:nth-child(2){
            animation-delay: .1s;
        }
        li:nth-child(3){
            animation-delay: .2s;
        }
        li:nth-child(4){
            animation-delay: .3s;
        }
    }
</style>
<script>
import {onMounted,watchEffect,nextTick} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
    props:['tdList'],
    setup(props){
        onMounted(()=>{
            // var wow = new WOW({
            // boxClass: 'wow',    //需要执行动画元素的Class
            // animateClass: 'animated',    //animation.css动画的Class
            // offset: 100,    //距离可视区域多少开始执行动画
            // mobile: true,    //是否在移动设备执行动画
            // live: true    //异步加载的内容是否有效
            // })
            // wow.init()
        })
        watchEffect(()=>{
            if(props.tdList!=""){
                nextTick(()=>{
                    var wow = new WOW({
                        boxClass: 'wow',    //需要执行动画元素的Class
                        animateClass: 'animated',    //animation.css动画的Class
                        offset: 10,    //距离可视区域多少开始执行动画
                        mobile: true,    //是否在移动设备执行动画
                        live: true,    //异步加载的内容是否有效
                        iteration:2
                    })
                    wow.init()
                })
            }
        })
    }
}
</script>