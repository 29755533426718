<template>
    <div class="section" >
        <h2 class="animate__animated animate__bounceInUp wow">{{title}}</h2>
        <img src="@/assets/images/tit.png" class="animate__animated animate__bounceInUp wow" />
    </div>
</template>
<style  lang="scss" scoped>
    .section{
        text-align: center;
        padding: .8rem 0 .5rem;
        h2{
            font-size: .36rem;
            font-weight: normal;
            letter-spacing: .05rem;
        }
        img{
            display: block;
            width: 2.58rem;
            margin: .1rem auto;
            animation-delay: .1s;
        }
    }
</style>
<script>
import {onMounted} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
export default {
    props:['title'],
    components:{

    },
    setup(){
        onMounted(()=>{
            var wow = new WOW({
                boxClass: 'wow',    //需要执行动画元素的Class
                animateClass: 'animated',    //animation.css动画的Class
                offset: 100,    //距离可视区域多少开始执行动画
                mobile: true,    //是否在移动设备执行动画
                live: true    //异步加载的内容是否有效
            })
            wow.init()
        })
    }
}
</script>